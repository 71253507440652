import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import { LightButton } from '../components/button'

export const FeaturesSection = (props) => (
  <section className="container mtt-5 mb-5">
    <div className="row">
      {props.features.map((feature) => (
        <div className="col-md-6 text-center mb-5" key={feature.title}>
          <div className="text-purple font-size-12">
            <i className={feature.icon}></i>
          </div>
          <h3 className="text-black">{feature.title}</h3>
          <p className="justify-text">{feature.description.description}</p>
        </div>
      ))}
    </div>
  </section>
)

// const StatisticsSection = (props) => (
//   <section className="container-fluid gradient-background text-white p-4 mb-5">
//     <div className="row">
//       {props.statistics.map((stat) => (
//         <div className="col-md-3 col-sm-12 my-4">
//           <div className="text-center">
//             <i className={`${stat.icon} font-size-8 mb-3`}></i>
//             <h2>{stat.description.description}</h2>
//             <p className="counter-item-text mb-0">{stat.title}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   </section>
// )

const SignificantNumberSection = ({
  bigSignificantNumber,
  subtitleOfBigSignificantNumber,
  detailsOfBigSignificantNumber,
}) => (
  <section className="container mt-9 mb-4">
    <div className="row">
      <div className="col-md-8 align-self-center">
        <div className="text-center">
          <h2 className="display-3 font-weight-bold text-purple">{bigSignificantNumber} Years</h2>
          <span>{subtitleOfBigSignificantNumber}</span>
        </div>
      </div>
      <div className="col-md-4 align-self-center text-center text-md-left">
        <p>{detailsOfBigSignificantNumber}</p>
        <LightButton to="/products">Explore products!</LightButton>
      </div>
    </div>
  </section>
)

const WorkflowSection = (props) => (
  <section className="container mt-9 mb-4">
    <div className="row mt-9">
      {props.workflow.map((stage, key) => (
        <div className="col-md-4 col-sm-12 workflow" key={key}>
          <div className="workflow workflow-number">{key + 1}.</div>
          <h4>{stage.title}</h4>
          <p>{stage.description.description}</p>
        </div>
      ))}
    </div>
  </section>
)

const SolutionsChecklist = ({ item }) => (
  <li className="list-group-item border-0">
    <i className="fas fa-check-circle text-purple mr-2"></i>
    {item}
  </li>
)

const SolutionsSections = ({
  notTypicalWorkflow,
  notTypicalWorkflowDescription,
  notTypicalWorkflowList,
  notTypicalWorkflowImage,
}) => (
  <section className="container mt-5">
    <div className="row justify-content-between">
      <div className="col-lg-7 col-md-12 pr-5 py-0">
        <div className="h-100">
          <div className="text-left p-0">
            <h4 className="">{notTypicalWorkflow}</h4>
            <p>{notTypicalWorkflowDescription}</p>
          </div>
          <div className="row">
            <div className="col">
              <ul className="list-group mb-0">
                {notTypicalWorkflowList.map((checklist) => (
                  <SolutionsChecklist item={checklist} key={checklist} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 d-none d-lg-block p-0">
        <div
          className="image-in-div roundedpse"
          style={{ backgroundImage: `url(https:${notTypicalWorkflowImage})` }}></div>
      </div>
    </div>
  </section>
)

export default (props) => {
  const {
    pageTitle,
    bigSignificantNumber,
    subtitleOfBigSignificantNumber,
    detailsOfBigSignificantNumber: { detailsOfBigSignificantNumber },
    workflow,
    notTypicalWorkflow,
    notTypicalWorkflowDescription: { notTypicalWorkflowDescription },
    notTypicalWorkflowList,
    notTypicalWorkflowImage: {
      file: { url: notTypicalWorkflowImageURL },
    },
    features,
  } = props.data.allContentfulPageAboutUsModal.nodes[0]
  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <SignificantNumberSection
          bigSignificantNumber={bigSignificantNumber}
          subtitleOfBigSignificantNumber={subtitleOfBigSignificantNumber}
          detailsOfBigSignificantNumber={detailsOfBigSignificantNumber}
        />
        <WorkflowSection workflow={workflow} />
        <SolutionsSections
          notTypicalWorkflow={notTypicalWorkflow}
          notTypicalWorkflowDescription={notTypicalWorkflowDescription}
          notTypicalWorkflowList={notTypicalWorkflowList}
          notTypicalWorkflowImage={notTypicalWorkflowImageURL}
        />
        <FeaturesSection features={features} />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutUsPage {
    allContentfulPageAboutUsModal {
      nodes {
        pageTitle
        bigSignificantNumber
        detailsOfBigSignificantNumber {
          detailsOfBigSignificantNumber
        }
        subtitleOfBigSignificantNumber
        workflow {
          description {
            description
          }
          title
        }
        notTypicalWorkflow
        notTypicalWorkflowDescription {
          notTypicalWorkflowDescription
        }
        notTypicalWorkflowList
        notTypicalWorkflowImage {
          file {
            url
          }
        }
        features {
          icon
          title
          description {
            description
          }
        }
        statistics {
          icon
          title
          description {
            description
          }
        }
      }
    }
  }
`
